<script>
import { useRouter } from 'vue-router';
import { computed } from 'vue';
export default {
  name: 'floatingOrder',
  data() {
    return {
      cartItems: []  // Список товаров в корзине
    };
  },
  mounted() {
    this.loadCartItems();
  },
  setup() {
    const router = useRouter();

    // Массив для хранения товаров в корзине
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];

    // Проверка: корзина не пуста
    const canPlaceOrder = computed(() => cartItems.length > 0);

    // Переход на страницу оформления заказа с проверкой
    const goToOrders = () => {
      if (!canPlaceOrder.value) {
        // Если корзина пуста, ничего не делаем
        return;
      }
      router.push({ name: 'Orders' });
    };

    return { goToOrders};
  },
  computed: {
    canPlaceOrder() {
      return this.cartItems.length > 0;
    },
  },
  methods: {
    closeOrder() {
      this.$emit('close');
    },
    loadCartItems() {
      // Получаем товары из localStorage
      this.cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    },
    clearCart() {
      // Очищаем корзину и localStorage
      localStorage.removeItem("cart");
      this.cartItems = [];
    }
  },
}
</script>

<template>
  <div class="container" @click="closeOrder">
    <div class="wrap" @click.stop>
      <div class="order">
        <div :class="{ 'goods': !canPlaceOrder, 'disabled': canPlaceOrder }">Нет добавленных товаров</div>
        <div class="goods" v-for="item in cartItems" :key="item.id">
          <div class="label">{{ item.label }}</div><div class="strip"><hr></div><div class="num">{{ item.quantity  }}</div>
        </div>
      </div>

      <div @click="goToOrders" :class="{ 'dis_btn': !canPlaceOrder, 'ord_btn': canPlaceOrder }">К оформлению</div>
    </div>
  </div>
</template>

<style scoped>
@media (min-width: 769px) {
  .disabled{
     display: none;
  }
  .dis_btn{
    background-color: #ccc;
    cursor: not-allowed;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 26px;
    width: 268px;
    height: 37px;
    color: white;
  }
  .order{
    width: 80%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .num{

  }
  .label{
    display: flex;
    text-align: center;
    height: 100%;
    align-items: center;
  }
  .strip{
    padding: 0 10px;
    margin-top:auto;
    height:10px;
    flex-grow: 1;
  }
  .goods{
    color: #717171;
    font-size: 20px;
    height: 50px;
    width: 100%;
    flex-direction:row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container{
    z-index: 200000;
    position: fixed;
    background-color: rgba(0,0,0,0.2);
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrap{
    padding: 20px 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 200000;
    background-color: white;
    border-radius: 15px;
    width: 35vw;
    min-height: 25vh;
    display: flex;
  }
  .ord_btn {
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 26px;
    width: 268px;
    height: 37px;
    background-color: #B996AD;
    color: white;
  }
  .wrap p{
    color: #717171;
  }
}
@media (max-width: 768px) {
  .disabled{
    display: none;
  }
  .dis_btn{
    background-color: #ccc;
    cursor: not-allowed;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 26px;
    width: 268px;
    height: 37px;
    color: white;
  }
  .order{
    width: 80%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .label{
    display: flex;
    height: 100%;
    align-items: center;
  }
  .strip{
    padding: 0 10px;
    margin-top:auto;
    height:10px;
    flex-grow: 1;
  }
  .goods{
    color: #717171;
    font-size: 16px;
    height: 50px;
    width: 100%;
    flex-direction:row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .container {
    z-index: 200000;
    position: fixed;
    background-color: rgba(0,0,0,0.2);
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrap{
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 200000;
    background-color: white;
    border-radius: 15px;
    width: 80%;
    min-height: 225px;
    display: flex;
  }
  .ord_btn {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 26px;
    width: 50%;
    height: 37px;
    background-color: #B996AD;
    color: white;
  }
  .wrap p{
    color: #717171;
  }
}
</style>