import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './HomePage.vue'
import OrdersPage from './OrdersPage.vue';

const routes = [
    { path: '/', name: 'HomePage', component: HomePage },
    { path: '/orders', name: 'Orders', component: OrdersPage},
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;