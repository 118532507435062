import {config, DefaultApiInstance} from "@/api";

export const CardAPI ={
 async getCard (page,limit){
     const url = `${config.backendIP}/card`
     const params={
         page,
         limit
     }
     const req = await DefaultApiInstance.get(url, {params})
     console.log(req)
     return req.data

 }
}
