import {config, DefaultApiInstance} from "@/api";

export const OrderAPI ={
    async sendOrder (phone,cards, fio ){
        const url = `${config.backendIP}/orders`
        const req = await DefaultApiInstance.post(url, {
            phone,
            cards,
            fio})
        console.log(req)
        return req.data

    }
}
