<script>
import {useRouter} from "vue-router";
import {OrderAPI} from "@/api/OrderAPI";
// import {OrderAPI} from "@/api/OrderAPI";
export default {
  name: 'OrdersPage',
  data() {
    return {
      cartItems: [],
      userData: {
        name: "",
        phone: "",
        wishes: ""
      }
    };
  },

  setup() {
    const router = useRouter();
    const goBack = () => {
      router.push({ name: 'HomePage' });
    };

    return {goBack};
  },
  computed: {
    canPlaceOrder() {
      // Проверяем, что все поля заполнены и в корзине есть товары
      return (
          this.cartItems.length > 0 &&
          this.userData.name.trim() !== "" &&
          this.userData.phone.trim() !== ""

      );
    },
    totalPrice() {
      return this.cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
    }
  },
  mounted() {
    this.cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    console.log(this.cartItems)
  },
  methods: {
    async placeOrder() {
      if (!this.canPlaceOrder) {
        alert("Заполните все поля и добавьте товары в корзину для оформления заказа.");
        return;
      }
      // Логика оформления заказа
      const response = await OrderAPI.sendOrder(this.userData.phone, this.cartItems.map(item => {
        return {
          cardId: item.id,
          count: item.quantity
        }
      }),
          this.userData.name)
      console.log(response)
      alert("Заказ оформлен!");
      localStorage.removeItem("cart");
      this.cartItems = [];
      this.userData = { name: "", phone: "", wishes: "" };
    },
    increaseQuantity(item) {
      item.quantity += 1;
      this.updateCart();
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity -= 1;
      } else {
        this.removeItem(item);  // Удаляем товар, если количество меньше 1
      }
      this.updateCart();
    },
    removeItem(item) {
      this.cartItems = this.cartItems.filter(cartItem => cartItem.id !== item.id);
      this.updateCart();
    },
    updateCart() {
      localStorage.setItem("cart", JSON.stringify(this.cartItems));
    },
  }

}
</script>

<template>
 <div class="wrapper">
   <div class="back" @click=goBack><img alt="назад" src="/img/leftarrow.svg"></div>
   <p class="title">КОРЗИНА</p>
   <div class="order-list">
     <div class="wrap" v-for="item in cartItems" :key="item.id">
       <div class="name">{{ item.label }}</div>
       <div class="price">{{ item.price*item.quantity }} руб.</div>
       <div class="pls_mns">
          <div style="margin-top: -5px;" @click=decreaseQuantity(item)>-</div>
          <div class="num">{{item.quantity}}</div>
          <div @click=increaseQuantity(item)>+</div>
       </div>
     </div>
   </div>
   <div class="end_price">
        Итоговая стоимость заказа: {{this.totalPrice}} руб.
   </div>
   <div class="person">
     <input v-model="userData.name" type="text" placeholder="Имя" class="fields">
     <input v-model="userData.phone" type="text" placeholder="Телефон" class="fields">
     <textarea v-model="userData.wishes" placeholder="Коментарий" class="fields"></textarea>
   </div>
   <div @click="canPlaceOrder ? placeOrder() : null"   :class="{ 'btn': true, 'disabled': !canPlaceOrder }"> Оформить заказ</div>
 </div>
</template>

<style scoped>
@media (min-width: 769px) {
  .back{
    display: flex;
    position: fixed;
    left: 40px;
    top: 5vh;
    cursor: pointer;
    width: 50px;
    height: 50px;
  }
  .back img{
    width: 40px;
  }
  .end_price{
    color: white;
    font-size: 25px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 80vw;
  }
  .disabled::before {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }
  .disabled:hover::before {
    position: fixed;
    content: 'Ваша карзина и данные должны быть заполнены';
    transform: translatey(250%);
    background-color: rgb(0, 0, 0, 0.4);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 30px;
    white-space: nowrap;
    opacity: 1;
    visibility: visible;
  }
  .num{
    font-size: 30px;
  }
  .btn.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .pls_mns{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 64px;
    margin:0 10px;
    font-size: 36px;
  }
  .price{
    margin-left: auto;
    margin-right: 40px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background-color: #CAB0A4;
  }
  .btn{
    font-size: 30px;
    cursor: pointer;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 90px;
    background-color: white;
    width: 470px;
    height: 71px;
    margin-bottom: 50px;
  }
  .wrap{
    justify-content: space-between;
    font-size: 25px;
    display: flex;
    align-items: center;
    padding: 0 30px 0 30px;
    width:100%;
    height: 71px;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 20px;
  }
  .order-list {
    width: 80%;
    margin-top: 10vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  .person {
    margin-top: 10vh;
    margin-bottom: 100px;
    width: 80%;
    align-items: flex-start;
  }
  .fields{
    font-size: 25px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    width:40%;
    height: 71px;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 20px;
  }
  .title {
    margin-top: 5vh;
    color: white;
    font-size: 56px;
  }
  textarea::placeholder {
    text-align: center;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 122px;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 768px) {
  .back{
    display: flex;
    position: fixed;
    left: 20px;
    top: 3vh;
    cursor: pointer;
    width: 50px;
    height: 50px;
  }
  .back img{
    width: 40px;
  }
  .end_price{
    color: white;
    font-size: 16px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 80vw;
  }
  .disabled::before {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }
  .disabled:hover::before {
    position: fixed;
    content: 'Ваша карзина и данные должны быть заполнены';
    transform: translatey(140%);
    width: 70%;
    background-color: rgb(0, 0, 0, 0.4);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 20px;
    white-space: wrap;
    opacity: 1;
    visibility: visible;
  }
  .btn.disabled {
    color: white;
    background-color: #ccc;
    cursor: not-allowed;
  }
  .pls_mns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 44px;
    margin:0 10px;
    font-size: 20px;
  }
  .price{
    margin-left: auto;
    margin-right: 10px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background-color: #CAB0A4;
  }
  .btn{
    font-size: 20px;
    cursor: pointer;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 90px;
    background-color: white;
    width: 70%;
    height: 40px;
    margin-bottom: 50px;
  }
  .wrap{
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 5px 0 5px 15px;
    width:100%;
    min-height: 30px;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 20px;
  }
  .order-list {
    width: 90%;
    margin-top: 10vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  .person {
    margin-top: 10vh;
    margin-bottom: 7vh;
    width: 90%;
    align-items: flex-start;
  }
  .fields{
    justify-content: center;
    min-height: 40px;
    overflow: hidden;
    font-size: 20px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    width:60%;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 20px;
  }
  textarea::placeholder {
    text-align: center;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 87px;
    transform: translate(-50%, -50%);
  }
  .title {
    margin-top: 5vh;
    color: white;
    font-size: 36px;
  }
}

</style>