<script>

export default {
  name: "productCard",
  props: {
    cardData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showToast: false,
      imageName: ""
    }

  },
  methods: {
    getImageUrl(imageName) {
      const baseUrl = 'https://s3.timeweb.cloud/30489bee-storelanding/';
      return `${baseUrl}${imageName}`;
    },
    addToCart() {
      const currentCart = JSON.parse(localStorage.getItem("cart")) || [];

      // Проверяем, есть ли товар уже в корзине
      const existingItem = currentCart.find(item => item.id === this.cardData.id);

      if (existingItem) {
        // Если товар уже есть в корзине, увеличиваем его количество
        existingItem.quantity += 1;
      } else {
        // Если товара нет в корзине, добавляем его с количеством 1
        currentCart.push({ ...this.cardData, quantity: 1 });
      }
      this.showToast=true;
      setTimeout(() => (this.showToast = false), 2000);
      localStorage.setItem("cart", JSON.stringify(currentCart));
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <img :src="getImageUrl(cardData.image)">
    <p class="rzdl">{{cardData.category}}</p>
    <p style=" margin-top: 7px">{{ cardData.label }}</p>
    <p style=" margin-top: 10px">{{ cardData.price }} руб.</p>
    <button @click=addToCart>ДОБАВИТЬ К ЗАКАЗУ</button>
    <div v-if="showToast" class="toast">Товар добавлен в корзину!</div>
  </div>

</template>

<style scoped>
@media (min-width: 769px) {
  .toast {
    width: 70vw;
    position: fixed;
    bottom: 20px;
    left: 15vw;
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    animation: fadeOut 2s forwards;
  }

  @keyframes fadeOut {
    0% { opacity: 1; }
    80% { opacity: 1; }
    100% { opacity: 0; }
  }
  .rzdl{
    display: none;
  }
  .spacer{
    width: 235px;
    height: 10px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
    flex-direction: column;
  }

  .wrapper img {
    border-radius: 10px;
    width: 450px;
    height: 450px;
  }

  .wrapper p {
    font-size: 30px;
    max-width: 460px;
    color: #785E53;
  }

  .wrapper button {
    cursor: pointer;
    margin-top: 30px;
    height: 50px;
    width: 18.2vw;
    min-width: 262px;
    color: white;
    font-family: "Roboto Light", sans-serif;
    font-weight: bold;
    font-size: 16px;
    border: solid 0;
    border-radius: 10px;
    background-color: #CAB0A4;
  }
}

@media (max-width: 768px) {
  .toast {
    z-index: 300000;
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    animation: fadeOut 2s forwards;
  }

  @keyframes fadeOut {
    0% { opacity: 1; }
    80% { opacity: 1; }
    100% { opacity: 0; }
  }
  .wrapper {
    margin-top: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    flex-shrink: 0;
    margin-bottom: 50px;
  }

  .wrapper img {
    border-radius: 10px;
    width: 258px;
    height: 258px;
  }
  .rzdl{
    margin-top: 19px;
    font-size: 30px;
    color: #785E53;
  }
  .wrapper p {
    max-width: 258px;
    color: #785E53;
  }

  .wrapper button {
    cursor: pointer;
    margin-top: 7px;
    height: 50px;
    width: 258px;
    color: white;
    font-family: "Roboto Light", sans-serif;
    font-weight: bold;
    font-size: 16px;
    border: solid 0;
    border-radius: 10px;
    background-color: #CAB0A4;
  }
}
</style>
